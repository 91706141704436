import React, {useMemo} from 'react'
import {useLocation} from 'react-router'
import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import ReportSearch from '../../components/Reports/ReportSearch'
import ReportUpdateModal from '../../components/Reports/ReportUpdateModal'
import getQuery from '../../lib/getQuery'
import handleFetchReports from '../../api/reports/handleFetchReports'

const columns = [
  {
    key: 'contentComment',
    dataIndex: ['contentComment', 'comment'],
    title: '게시글 댓글',
    render: (data: string) => (!data ? '해당사항 없음' : data)
  },
  {
    key: 'answerCommnet',
    dataIndex: ['answerComment', 'commnet'],
    title: '답변 댓글',
    render: (data: string) => (!data ? '해당사항 없음' : data)
  },
  {
    key: 'gustBook',
    dataIndex: ['gustBook', 'comment'],
    title: '방명록 댓글',
    render: (data: string) => (!data ? '해당사항 없음' : data)
  },
  {key: 'reporter', dataIndex: ['reporter', 'nickname'], title: '신고자'},
  {key: 'target', dataIndex: ['target', 'nickname'], title: '신고 대상자'},
  {key: 'type', dataIndex: 'type', title: '타입'}
]

function Reports() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  return (
    <PageProvider onFetch={() => handleFetchReports(query)} uniqueKey="reports">
      <ReportSearch />

      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />
      <ReportUpdateModal />
    </PageProvider>
  )
}

export default Reports
