import React, {useCallback, useContext, useState} from 'react'
import {Button, message, Modal, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useMutation} from 'react-query'

import MateForm from './MateForm'
import {postMateAction} from '../../api/mateActions'
import {postMate} from '../../api/mate'
import PageContext from '../../contexts/page/PageContext'
import {AxiosError} from 'axios'
import {uploadImage} from '../../lib/uploadImage'

function MateCreateModal(props: ModalProps) {
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const {onDataFetch} = useContext(PageContext)
  const [label, setLabel] = useState<string | undefined>()
  const [action, setAction] = useState<string | undefined>()

  const handleOpen = useCallback(() => {
    form.resetFields()
    setIsModalVisible(true)
  }, [form])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const {mutate, isLoading} = useMutation(postMate, {
    onSuccess: async ({data}) => {
      try {
        !!action &&
          !!label &&
          (await postMateAction({
            mateId: data.id,
            action,
            label
          }))

        setLabel(undefined)
        setAction(undefined)
      } catch (e) {
        message.error('메이트 특성화 메뉴 생성에 실패했습니다.')
      }
      if (onDataFetch) onDataFetch()
      handleClose()
    },
    onError: (e: AxiosError<any, any>) => {
      if (e.response) message.error(e.response.data.message)
      else message.error('오류가 발생했습니다.')
    }
  })

  const handleFinish = useCallback(async (values: IPostMateData) => {
    let formedProfileImage = ''
    let formedBackGroundImage = ''
    const {hashtags, sendbirdId, name, profileImage, backGroundImage, description, action, label} = values
    setAction(action)
    setLabel(label)

    if (!profileImage || !backGroundImage) {
      message.error('프로필 이미지, 배경 이미지를 첨부해주세요.')
      return
    }

    const formedHashtags = hashtags.map((item: string) => {
      return {
        name: item
      }
    })

    if (profileImage && typeof profileImage !== 'string') {
      formedProfileImage = await uploadImage(profileImage[0], 'images')
    }
    if (backGroundImage && typeof backGroundImage !== 'string') {
      formedBackGroundImage = await uploadImage(backGroundImage[0], 'images')
    }

    mutate({
      hashtags: formedHashtags,
      sendbirdId: sendbirdId,
      name: name,
      profileImage: formedProfileImage,
      backGroundImage: formedBackGroundImage,
      description: description
    })
  }, [])

  return (
    <>
      <Button onClick={handleOpen}>메이트 생성</Button>
      <Modal
        title="메이트 생성"
        okText="생성"
        cancelText="취소"
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleClose}
        confirmLoading={isLoading}
        {...props}
      >
        <MateForm type="create" form={form} onFinish={handleFinish} />
      </Modal>
    </>
  )
}

export default MateCreateModal
