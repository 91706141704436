import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import {message, Modal, Form as AntdForm, InputNumber, Card} from 'antd'
import {useLocation} from 'react-router-dom'
import getQuery from '../../lib/getQuery'
import {AxiosError} from 'axios'
import {useForm} from 'antd/lib/form/Form'
import PageContext from '../../contexts/page/PageContext'
import {useMutation} from 'react-query'
import handleFetchContents from '../../api/contents/handleFetchContents'
import Upload from '../Upload'
import TextArea from 'antd/lib/input/TextArea'
import {putContent} from '../../api/contents'
import {uploadImage} from '../../lib/uploadImage'

interface IProps {
  isModalOpen: boolean
  handleCancel: () => void
}
const {Item} = AntdForm

function EditContentModal({isModalOpen, handleCancel}: IProps) {
  const [form] = useForm()
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  const {onDataFetch} = useContext(PageContext)
  const [contents, setContents] = useState<any>()

  useEffect(() => {
    fetchContents()
  }, [query.updateByMateId])

  async function fetchContents() {
    const {data} = await handleFetchContents({
      start: 0,
      perPage: 100,
      mateId: query.updateByMateId
    })
    form.setFieldsValue({arr: data})
    setContents(data)
  }

  const onSuccess = useCallback(() => {
    message.success('정상적으로 처리되었습니다.')
    handleCancel()
  }, [])
  const onError = useCallback((e: AxiosError<any, any>) => {
    if (e.response) message.error(e.response.data.message)
    else message.error('오류가 발생했습니다.')
  }, [])

  const {mutate: putMutate} = useMutation(putContent, {onSuccess, onError})

  const getContents = async (arr: any[]) => {
    const contents = []

    for (let content of arr) {
      const images = []
      for (let image of content.images) {
        const url = await uploadImage(image, 'images')
        images.push(url)
      }
      contents.push({id: content.id, content: content.content, images})
    }

    return contents
  }

  const handleOk = useCallback(
    async ({arr}: any) => {
      const contents = await getContents(arr)

      putMutate({
        contents: contents,
        mateId: Number(query.updateByMateId)
      })
    },
    [query.updateByMateId]
  )

  useLayoutEffect(() => {
    return () => {
      form.resetFields()
      if (onDataFetch) onDataFetch()
    }
  }, [query.updateByMateId])

  return (
    <>
      <Modal title="메이트 별 게시글 수정 " open={isModalOpen} onOk={form.submit} onCancel={handleCancel}>
        <AntdForm form={form} onFinish={handleOk}>
          <AntdForm.List name="arr">
            {(fields) =>
              fields &&
              fields?.map((item: any) => (
                <Card
                  key={item.id}
                  title={`게시글 #${form.getFieldValue(['arr', item.name, 'id'])}`}
                  style={{marginBottom: '15px'}}
                >
                  <Item name={[item.name, 'id']} label="게시글 ID">
                    <InputNumber placeholder="게시글 ID를 입력해주세요." autoComplete="off" />
                  </Item>
                  <Item name={[item.name, 'content']} label="내용">
                    <TextArea placeholder="게시글 내용을 입력해주세요." autoComplete="off" />
                  </Item>
                  {contents[item.name] && (
                    <Item name={[item.name, 'images']} label="이미지">
                      <Upload
                        max={10}
                        title="images"
                        form={form}
                        arrname={item.name}
                        values={contents[item.name].images}
                        multiple
                      />
                    </Item>
                  )}
                </Card>
              ))
            }
          </AntdForm.List>
        </AntdForm>
      </Modal>
    </>
  )
}

export default EditContentModal
