import React from 'react'
import {Form as AntdForm, FormProps, Input, Radio} from 'antd'
import Form from '../Form'
import Password from '../Password'
import Upload from '../Upload'
import UserFollowingTable from './UserFollowingTable'

const {Item} = AntdForm

function UserForm({type, ...rest}: FormProps & {type: 'update'} & {userData: IUesr | undefined}) {
  return rest.form ? (
    <Form {...rest}>
      <Item
        name="nickname"
        label="닉네임"
        rules={[{type: 'string', min: 2, max: 20, message: '2 ~ 20자 이내로 입력해주세요.'}]}
      >
        <Input placeholder="이름을 입력해주세요." autoComplete="off" />
      </Item>
      <Item name="description" label="메모" rules={[{type: 'string'}]}>
        <Input placeholder="메모을 입력해주세요." autoComplete="off" />
      </Item>
      <Item name="profileImage" label="프로필 이미지" valuePropName="values">
        <Upload max={1} title="profileImage" form={rest.form} />
      </Item>
      <Item name="marketing" label="마케팅 수신 동의 여부" rules={[{type: 'boolean'}]}>
        <Radio.Group
          optionType={'button'}
          buttonStyle={'outline'}
          options={[
            {
              label: '동의',
              value: true
            },
            {
              label: '미동의',
              value: false
            }
          ]}
        />
      </Item>
      <Item
        name="birth"
        label="생년월일"
        rules={[{type: 'string', min: 8, max: 8, message: '8자리의 숫자 형식으로 입력해주세요.'}]}
      >
        <Input placeholder="생년월일을 입력해주세요." autoComplete="off" />
      </Item>

      <Password
        rules={[
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
            message: '올바르지 않은 형식입니다.'
          }
        ]}
      />

      <Password
        type="confirm"
        name="confirm"
        label="비밀번호 확인"
        rules={[
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
            message: '올바르지 않은 형식입니다.'
          }
        ]}
      />

      <Item name="sendbirdId" label="샌드버드 아이디">
        <Input autoComplete="off" disabled />
      </Item>

      <Item name="userFollowingTable" label="팔로잉">
        <UserFollowingTable userData={rest.userData} />
      </Item>
    </Form>
  ) : (
    <></>
  )
}

export default UserForm
