import React from 'react'
import {Form as AntdForm, FormProps, Input, InputNumber} from 'antd'
import Form from '../Form'

const {Item} = AntdForm

function MateForm({type, ...rest}: FormProps & {type: 'create' | 'update'} & {feedback?: IFeedback}) {
  const {TextArea} = Input

  return (
    <Form {...rest}>
      {type === 'create' && (
        <>
          <Item name="mateId" label="메이트 ID">
            <InputNumber placeholder="메이트 ID" autoComplete="off" min={0} />
          </Item>

          <Item name="userId" label="유저 ID">
            <InputNumber placeholder="유저 ID" autoComplete="off" min={0} />
          </Item>

          <Item name="rating" label="평점" rules={[{required: type === 'create'}]}>
            <InputNumber placeholder="평점" autoComplete="off" min={0} />
          </Item>

          <Item name="description" label="리뷰" rules={[{type: 'string'}, {required: type === 'create'}]}>
            <TextArea placeholder="리뷰를 입력해주세요." autoComplete="off" />
          </Item>
        </>
      )}
      {type === 'update' && (
        <>
          <Item name="mateId" label="메이트 ID">
            <InputNumber
              placeholder={!!rest?.feedback?.mate?.id ? String(rest?.feedback?.mate?.id) : '없음'}
              autoComplete="off"
              disabled
            />
          </Item>

          <Item name="userId" label="유저 ID">
            <InputNumber
              placeholder={!!rest?.feedback?.user?.id ? String(rest?.feedback?.user?.id) : '없음'}
              autoComplete="off"
              disabled
            />
          </Item>

          <Item name="rating" label="평점">
            <InputNumber placeholder="평점" autoComplete="off" min={0} />
          </Item>

          <Item name="description" label="리뷰" rules={[{type: 'string'}]}>
            <TextArea placeholder="리뷰를 입력해주세요." autoComplete="off" />
          </Item>
        </>
      )}
    </Form>
  )
}
//피드백, 팔로워, 라이크, 디엠
export default MateForm
