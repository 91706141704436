import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/searchKeywords'

function postSearchKeyword(data: IPostSearchKeywordData) {
  return request({url: PATH, method: 'POST', data})
}

function getSearchKeywords(params: IGetSearchKeywordsParams): AxiosPromise<ISearchKeywords> {
  return request({url: PATH, method: 'GET', params})
}

function getSearchKeyword({id}: IGetSearchKeywordParams): AxiosPromise<ISearchKeyword> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function patchSearchKeyword({id, data}: IPatchSearchKeywordParams) {
  return request({url: `${PATH}/${id}`, method: 'PATCH', data})
}

function deleteSearchKeyword({id}: IDeleteSearchKeywordParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {postSearchKeyword, getSearchKeyword, getSearchKeywords, patchSearchKeyword, deleteSearchKeyword}
