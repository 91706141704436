import React, {useMemo} from 'react'
import {useLocation} from 'react-router'
import BlockActions from '../../components/Blocks/BlockActions'
import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import handleFetchBlocks from '../../api/blocks/handleFetchBlocks'

import BlockUpdateModal from '../../components/Blocks/BlockUpdateModal'
import getQuery from '../../lib/getQuery'

const columns = [
  {key: 'user', dataIndex: ['user', 'nickname'], title: '신고 사용자'},
  {key: 'blockUser', dataIndex: ['blockUser', 'nickname'], title: '신고 해당 사용자'}
]

function Users() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  return (
    <PageProvider onFetch={() => handleFetchBlocks(query)} uniqueKey="blocks">
      <BlockActions />

      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />

      <BlockUpdateModal />
    </PageProvider>
  )
}

export default Users
