import {getContentComments} from './index'
import {message} from 'antd'

async function handleFetchContentComments(params: IGetContentCommentsParams) {
  try {
    const {data} = await getContentComments(params)

    return data
  } catch (e: any) {
    message.error(e.message || '게시글 댓글 데이터를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return null
  }
}

export default handleFetchContentComments
