import {getUsers} from './index'
import {message} from 'antd'

async function handleFetchUsers(params: IGetUsersParams) {
  try {
    const {data} = await getUsers(params)

    if (data.total < 1 && !!params.search) throw new Error('검색 결과가 없습니다')

    return data
  } catch (e: any) {
    message.error(e.message || '회원 목록을 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return
  }
}

export default handleFetchUsers
