import React, {useCallback, useContext, useState} from 'react'
import {Button, message, Modal, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useMutation} from 'react-query'
import FeedbackForm from './FeedbackForm'
import PageContext from '../../contexts/page/PageContext'
import {AxiosError} from 'axios'
import {postFeedback} from '../../api/feedbacks'

function FeedbackCreateModal(props: ModalProps) {
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const {onDataFetch} = useContext(PageContext)

  const handleOpen = useCallback(() => {
    form.resetFields()
    setIsModalVisible(true)
  }, [form])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const {mutate, isLoading} = useMutation(postFeedback, {
    onSuccess: () => {
      if (onDataFetch) onDataFetch()
      handleClose()
    },
    onError: (e: AxiosError<any, any>) => {
      if (e.response) message.error(e.response.data.message)
      else message.error('오류가 발생했습니다.')
    }
  })

  const handleFinish = useCallback(async (values: IPostFeedbackData) => {
    const {mateId, userId, rating, description} = values

    mutate({
      mateId: mateId || null,
      userId: userId || null,
      rating: rating,
      description: description
    })
  }, [])

  return (
    <>
      <Button onClick={handleOpen}>메이트 리뷰 생성</Button>
      <Modal
        title="메이트 리뷰 생성"
        okText="생성"
        cancelText="취소"
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleClose}
        confirmLoading={isLoading}
        {...props}
      >
        <FeedbackForm type="create" form={form} onFinish={handleFinish} />
      </Modal>
    </>
  )
}

export default FeedbackCreateModal
