import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/questions'

function postQuestion(data: IPostQuestionData) {
  return request({url: PATH, method: 'POST', data})
}

function getQuestions(params: IGetQuestionsParams): AxiosPromise<IQuestions> {
  return request({url: PATH, method: 'GET', params})
}

function getQuestion({id}: IGetQuestionParams): AxiosPromise<IQuestion> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function patchQuestion({id, data}: IPatchQuestionParams) {
  return request({url: `${PATH}/${id}`, method: 'PATCH', data})
}

export {postQuestion, getQuestion, getQuestions, patchQuestion}
