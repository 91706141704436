import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'
import {useQuery, useMutation} from 'react-query'
import {AxiosError} from 'axios'
import queryString from 'query-string'
import QuestionForm from './QuestionForm'
import {patchQuestion} from '../../api/questions'
import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import getQuery from '../../lib/getQuery'
import handleFetchQuestion from '../../api/questions/handleFetchQuestion'

function QuestionUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const {onDataFetch} = useContext(PageContext)
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: '', search: queryString.stringify(query)}, {replace: true})
  }, [query])

  const onSuccess = useCallback(() => {
    message.success('정상적으로 처리되었습니다.')
    handleClose()
  }, [])
  const onError = useCallback((e: AxiosError<any, any>) => {
    if (e.response) message.error(e.response.data.message)
    else message.error('오류가 발생했습니다.')
  }, [])

  const {isFetching, data: question} = useQuery('question', () => handleFetchQuestion({id}), {
    enabled,
    onSuccess: (data: IQuestion) => {
      form.setFieldsValue(data)
      setEnabled(false)
    },
    onError: (e: AxiosError<any, any>) => {
      if (e.response) message.error(e.response.data.message)
      setEnabled(false)
    }
  })
  const {mutate: patchMutate, isLoading: isPatchLoading} = useMutation(patchQuestion, {onSuccess, onError})

  const handleFinish = useCallback(
    (data: IPatchQuestionData & {confirm?: string}) => {
      if (!id) return message.error('질문 데이터를 불러올 수 없습니다.')
      if (data.confirm) delete data.confirm

      if (!!data.picked) {
        data = {
          ...data,
          picked: JSON.parse(data.picked)
        }
        patchMutate({id, data})
        return
      }

      patchMutate({id, data})
    },
    [id]
  )

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
      setEnabled(true)
    }
    return () => {
      form.resetFields()
      if (onDataFetch) onDataFetch()
    }
  }, [id])

  return (
    <UpdateModal
      title={`오늘의 질문 ${hash}`}
      visible={isModalVisible}
      onOk={form.submit}
      onCancel={handleClose}
      isPatchLoading={isPatchLoading}
      isFetching={isFetching}
      {...props}
    >
      <QuestionForm type="update" form={form} onFinish={handleFinish} question={question} />
    </UpdateModal>
  )
}

export default QuestionUpdateModal
