import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/blocks'

function getBlocks(params: IGetBlocksParams): AxiosPromise<IBlocks> {
  return request({url: PATH, method: 'GET', params})
}

function getBlock({id}: IGetBlockParams): AxiosPromise<IBlock> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

export {getBlocks, getBlock}
