import {UserOutlined, AliwangwangOutlined, AppstoreAddOutlined, LikeOutlined} from '@ant-design/icons'
import Administrators from '../pages/users/Administrators'
import Users from '../pages/users/Users'
import Blocks from '../pages/users/Blocks'
import Withdrawals from '../pages/users/Withdrawals'
import Mates from '../pages/mates/Mates'
import Feedbacks from '../pages/mates/Feedbacks'
import MateNotices from '../pages/mates/MateNotices'
import {Navigate} from 'react-router'
import Answers from '../pages/contents/Answers'
import Comments from '../pages/contents/Comments'
import Contents from '../pages/contents/Contents'
import SearchKeywords from '../pages/contents/SearchKeywords'
import Notices from '../pages/contents/Notices'
import Questions from '../pages/contents/Questions'
import Reports from '../pages/contents/Reports'
import Inquiries from '../pages/services/Inquiries'
import InquiryTypes from '../pages/services/InquiryTypes'

export default [
  {
    path: '',
    element: <Navigate to="/auth" replace={true} />
  },
  {
    name: 'users',
    displayName: '사용자 관리',
    path: 'user',
    icon: <UserOutlined />,
    children: [
      {name: 'administrators', displayName: '운영진 관리', path: 'administrator', element: <Administrators />},
      {name: 'users', displayName: '회원 관리', path: 'users', element: <Users />},
      {name: 'blocks', displayName: '신고 사용자 관리', path: 'blocks', element: <Blocks />},
      {name: 'withdrawals', displayName: '탈퇴 회원 관리', path: 'withdrawals', element: <Withdrawals />}
    ]
  },
  {
    name: 'mates',
    displayName: '메이트 관리',
    path: 'mates',
    icon: <AliwangwangOutlined />,
    children: [
      {name: 'mate', displayName: '메이트 관리', path: 'mate', element: <Mates />},
      {name: 'feedbacks', displayName: '메이트 리뷰 관리', path: 'feedbacks', element: <Feedbacks />},
      {name: 'mateNotices', displayName: '메이트 공지 관리', path: 'mateNotices', element: <MateNotices />}
    ]
  },
  {
    name: 'contents',
    displayName: '컨텐츠 관리',
    path: 'contents',
    icon: <AppstoreAddOutlined />,
    children: [
      {name: 'answers', displayName: '메이트 댓글 관리', path: 'answers', element: <Answers />},
      {name: 'comments', displayName: '사용자 댓글 관리', path: 'comments', element: <Comments />},
      {name: 'content', displayName: '게시글 관리', path: 'content', element: <Contents />},
      {name: 'keywords', displayName: '검색 키워드 관리', path: 'keywords', element: <SearchKeywords />},
      {name: 'notices', displayName: '공지 관리', path: 'notices', element: <Notices />},
      {name: 'questions', displayName: '질문 관리', path: 'questions', element: <Questions />},
      {name: 'reports', displayName: '신고 댓글 관리', path: 'reports', element: <Reports />}
    ]
  },
  {
    name: 'services',
    displayName: '서비스 관리',
    path: 'services',
    icon: <LikeOutlined />,
    children: [
      {name: 'inquiries', displayName: '문의 관리', path: 'inquiries', element: <Inquiries />},
      {name: 'inquiriesType', displayName: '문의 타입 관리', path: 'inquiriesType', element: <InquiryTypes />}
    ]
  }
]
