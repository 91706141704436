import React, {useMemo} from 'react'
import {useLocation} from 'react-router'

import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import handleFetchNotices from '../../api/notices/handleFetchNotices'
import NoticeCreateModal from '../../components/Notices/NoticeCreateModal'
import NoticeUpdateModal from '../../components/Notices/NoticeUpdateModal'
import getQuery from '../../lib/getQuery'

const columns = [
  {key: 'name', dataIndex: ['admin', 'name'], title: '관리자 이름'},
  {key: 'nickname', dataIndex: ['admin', 'nickname'], title: '관리자 닉네임'},
  {key: 'title', dataIndex: 'title', title: '제목'},
  {key: 'contents', dataIndex: 'contents', title: '내용'}
]

function Notices() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  return (
    <PageProvider onFetch={() => handleFetchNotices(query)} uniqueKey="notices">
      <NoticeCreateModal />

      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />
      <NoticeUpdateModal />
    </PageProvider>
  )
}

export default Notices
