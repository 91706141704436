import React from 'react'
import {Card} from 'antd'
import dayjs from 'dayjs'

function ReportForm({report}: {report?: IReport}) {
  return (
    <>
      {!!report?.contentComment && (
        <Card title="게시물 댓글" size="small">
          <p>ID: {report?.contentComment?.id}</p>
          <p>댓글: {report?.contentComment?.comment}</p>
          <p>공개여부: {report?.contentComment?.hidden ? '비공개' : '공개'}</p>
          <p>생성일시: {dayjs(report?.contentComment?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
        </Card>
      )}

      {!!report?.answerComment && (
        <Card title="답변 댓글" size="small">
          <p>ID: {report?.answerComment?.id}</p>
          <p>댓글: {report?.answerComment?.comment}</p>
          <p>생성일시: {dayjs(report?.answerComment?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
        </Card>
      )}

      {!!report?.gustBook && (
        <Card title="방명록" size="small">
          <p>ID: {report?.gustBook?.id}</p>
          <p>댓글: {report?.gustBook?.comment}</p>
          <p>생성일시: {dayjs(report?.gustBook?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
        </Card>
      )}

      <Card title="신고자" size="small">
        <p>ID: {report?.reporter?.id}</p>
        <p>닉네임: {report?.reporter?.nickname}</p>
      </Card>

      <Card title="신고 대상자" size="small">
        <p>ID: {report?.target?.id}</p>
        <p>닉네임: {report?.target?.nickname}</p>
      </Card>

      <Card title="신고 댓글 ID" size="small">
        <p>ID: {report?.id}</p>
      </Card>

      <Card title="신고 댓글 타입" size="small">
        <p>타입: {report?.type}</p>
      </Card>

      <Card title="신고 댓글 생성일" size="small">
        <p>타입: {dayjs(report?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
      </Card>
    </>
  )
}

export default ReportForm
