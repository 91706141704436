import React from 'react'
import {Form as AntdForm, FormProps, Input, InputNumber} from 'antd'
import Form from '../Form'

const {Item} = AntdForm

function SearchKeywordForm({type, ...rest}: FormProps & {type: 'create' | 'update'}) {
  return (
    <Form {...rest}>
      <Item name="keyword" label="키워드" rules={[{type: 'string'}, {required: type === 'create'}]}>
        <Input placeholder="키워드를 입력해주세요." autoComplete="off" />
      </Item>

      <Item name="count" label="카운트" rules={[{type: 'number'}, {required: type === 'create'}]}>
        <InputNumber placeholder="count" autoComplete="off" />
      </Item>
    </Form>
  )
}

export default SearchKeywordForm
