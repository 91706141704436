import React, {useState, useCallback} from 'react'
import {SearchOutlined} from '@ant-design/icons'
import {Row, Button, InputNumber} from 'antd'
import EditContentModal from './EditContentModal'
import {useNavigate} from 'react-router'

function EditContent() {
  const [mateId, setMateId] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()

  const onChange = useCallback((value: number | null) => {
    value && setMateId(value)
  }, [])

  const onSearch = useCallback(() => {
    if (mateId === 0) {
      navigate(`/contents/content`)
      setIsModalOpen(false)
    } else {
      navigate(`?updateByMateId=${mateId}`)
      setIsModalOpen(true)
    }
  }, [mateId])

  return (
    <>
      <div style={{marginBottom: '15px', fontWeight: '700', marginTop: '20px'}}>메이트 별 게시글 수정</div>
      <Row>
        <InputNumber min={0} defaultValue={0} onChange={onChange} />
        <Button type="primary" icon={<SearchOutlined />} style={{marginLeft: '10px'}} onClick={onSearch}>
          메이트 ID로 검색 및 수정
        </Button>
      </Row>
      <EditContentModal
        isModalOpen={isModalOpen}
        handleCancel={() => {
          setIsModalOpen(false)
          navigate(`/contents/content`)
        }}
      />
    </>
  )
}

export default EditContent
