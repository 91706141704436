import React from 'react'
import {Spin} from 'antd'
import styled from 'styled-components'

export default function ModalLoading() {
  return (
    <Container>
      <Spin tip="Loading..." />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
`
