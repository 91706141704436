import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/notices'

function postNotice(data: IPostNoticeData) {
  return request({url: PATH, method: 'POST', data})
}

function getNotices(params: IGetNoticesParams): AxiosPromise<INotices> {
  return request({url: PATH, method: 'GET', params})
}

function getNotice({id}: IGetNoticeParams): AxiosPromise<INotice> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function patchNotice({id, data}: IPatchNoticeParams) {
  return request({url: `${PATH}/${id}`, method: 'PATCH', data})
}

function deleteNotice({id}: IDeleteNoticeParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {postNotice, getNotice, getNotices, patchNotice, deleteNotice}
