import React from 'react'
import {Form as AntdForm, FormProps, Input, InputNumber} from 'antd'
import Form from '../Form'

const {Item} = AntdForm

function AnswerForm({type, ...rest}: FormProps & {type: 'create' | 'update'} & {answer?: IAnswer}) {
  const {TextArea} = Input
  return (
    <Form {...rest}>
      {type === 'create' && (
        <>
          <Item name="questionId" label="질문 ID" rules={[{required: type === 'create'}]}>
            <InputNumber placeholder="질문 ID" autoComplete="off" min={0} />
          </Item>

          <Item name="mateId" label="메이트 ID" rules={[{required: type === 'create'}]}>
            <InputNumber placeholder="메이트 ID" autoComplete="off" min={0} />
          </Item>

          <Item name="answer" label="답변" rules={[{type: 'string'}, {required: type === 'create'}]}>
            <TextArea placeholder="답변을 입력해주세요." autoComplete="off" />
          </Item>
        </>
      )}

      {type === 'update' && (
        <>
          <Item name="questionId" label="질문 ID" rules={[{required: type === 'update'}]}>
            <InputNumber
              placeholder={!!rest.answer?.question?.id ? String(rest.answer?.question?.id) : '없음'}
              autoComplete="off"
              min={0}
            />
          </Item>

          <Item name="mateId" label="메이트 ID" rules={[{required: type === 'update'}]}>
            <InputNumber
              placeholder={!!rest.answer?.mate?.id ? String(rest.answer?.mate?.id) : '없음'}
              autoComplete="off"
              min={0}
            />
          </Item>

          <Item name="answer" label="답변" rules={[{type: 'string'}, {required: type === 'update'}]}>
            <TextArea placeholder="답변을 입력해주세요." autoComplete="off" />
          </Item>

          <Item label="질문">
            <TextArea placeholder={rest.answer?.question?.question} autoComplete="off" disabled />
          </Item>

          <Item label="질문 날짜">
            <Input placeholder={rest.answer?.question?.date} autoComplete="off" disabled />
          </Item>

          <Item label="메이트 이름">
            <Input placeholder={rest.answer?.mate?.name} autoComplete="off" disabled />
          </Item>

          <Item label="사용자 이름">
            <Input placeholder={rest.answer?.user?.nickname} autoComplete="off" disabled />
          </Item>

          <Item label="사용자 ID">
            <Input placeholder={String(rest.answer?.user?.id)} autoComplete="off" disabled />
          </Item>

          <Item label="좋아요 수">
            <InputNumber placeholder={String(rest.answer?.likeCount)} autoComplete="off" disabled />
          </Item>

          <Item label="댓글 수">
            <InputNumber placeholder={String(rest.answer?.commentCount)} autoComplete="off" disabled />
          </Item>
        </>
      )}
    </Form>
  )
}

export default AnswerForm
