import React from 'react'
import {Form as AntdForm, FormProps, Input} from 'antd'
import Form from '../Form'

const {Item} = AntdForm

function InquiryTypesForm({type, ...rest}: FormProps & {type: 'create' | 'update'}) {
  return (
    <Form {...rest}>
      <Item
        name="name"
        label="문의 타입"
        rules={[{type: 'string'}, {required: type === 'create' || type === 'update'}]}
      >
        <Input placeholder="문의 타입을 입력해주세요." autoComplete="off" />
      </Item>
    </Form>
  )
}

export default InquiryTypesForm
