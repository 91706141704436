import {getFeedback} from './index'
import {message} from 'antd'

async function handleFetchFeedback(params: IGetFeedbackParams) {
  try {
    const {data} = await getFeedback(params)

    if (!data.id || data.id !== params.id) throw new Error('해당 메이트 리뷰 정보가 없습니다.')

    return data
  } catch (e: any) {
    message.error(e.message || '메이트 리뷰 데이터를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return null
  }
}

export default handleFetchFeedback
