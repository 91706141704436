import React from 'react'
import {Form as AntdForm, FormProps, Input} from 'antd'
import Form from '../Form'

const {Item} = AntdForm

function NoticeForm({type, ...rest}: FormProps & {type: 'create' | 'update'} & {notice?: INotice}) {
  const {TextArea} = Input

  return (
    <Form {...rest}>
      <>
        <Item name="title" label="제목" rules={[{type: 'string'}, {required: type === 'create'}]}>
          <Input placeholder="제목을 입력해주세요." autoComplete="off" />
        </Item>

        <Item name="contents" label="내용" rules={[{type: 'string'}, {required: type === 'create'}]}>
          <TextArea placeholder="내용을 입력해주세요." autoComplete="off" />
        </Item>
      </>

      {type === 'update' && (
        <>
          <Item name="adminId" label="관리자 ID">
            <Input placeholder={String(rest.notice?.admin.id)} autoComplete="off" />
          </Item>
          <Item name="adminname" label="관리자 이름">
            <Input placeholder={rest.notice?.admin.name} autoComplete="off" />
          </Item>
          <Item name="adminnickname" label="관리자 닉네임">
            <Input placeholder={rest.notice?.admin.nickname} autoComplete="off" />
          </Item>
        </>
      )}
    </Form>
  )
}

export default NoticeForm
