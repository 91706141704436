import React, {useMemo} from 'react'
import {useLocation} from 'react-router'
import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import getQuery from '../../lib/getQuery'
import handleFetchInquiries from '../../api/inquiries/handleFetchInquiries'
import InquireSearch from '../../components/Inquiries/InquireSearch'

const columns = [
  {key: 'userId', dataIndex: ['user', 'id'], title: '사용자 ID'},
  {key: 'user', dataIndex: ['user', 'nickname'], title: '사용자'},
  {key: 'contents', dataIndex: 'contents', title: '내용'}
]

function Inquiries() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  return (
    <PageProvider onFetch={() => handleFetchInquiries(query)} uniqueKey="inquiries">
      <InquireSearch />
      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />
    </PageProvider>
  )
}

export default Inquiries
