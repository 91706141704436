import React, {useCallback, useMemo, useState} from 'react'
import {Avatar, Breadcrumb, Card, Divider, Layout, LayoutProps, Space, Typography} from 'antd'
import UserProvider from '../contexts/user/UserProvider'
import Header from './Header'
import SideMenu from './SideMenu'
import {Link, useLocation} from 'react-router-dom'
import {HomeOutlined} from '@ant-design/icons'
import routes from '../routes/routes'

function DashboardLayout({children, ...rest}: LayoutProps) {
  const {pathname} = useLocation()
  const [collapsed, setCollapsed] = useState(false)

  const paths = useMemo(() => (pathname === '/' ? [] : pathname.replace('/', '').split('/')), [pathname])

  const route = useMemo(() => {
    return (
      routes.find((route) => route.path === paths[0]) || {
        icon: undefined,
        path: undefined,
        children: undefined,
        displayName: '',
        name: ''
      }
    )
  }, [paths])

  const handleCollapsed = useCallback((collapsed: boolean) => {
    setCollapsed(collapsed)
  }, [])

  return (
    <UserProvider>
      <Layout {...rest} style={{minHeight: '100vh'}}>
        <Layout.Sider collapsible collapsed={collapsed} onCollapse={handleCollapsed} style={{padding: '40px 0'}}>
          <Link to="/">
            <aside
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Avatar size={!collapsed ? 64 : 24} src="/logo256.webp" />
              {!collapsed && (
                <Typography style={{color: 'white', textAlign: 'center', marginTop: 10}}>TUNiB ADMIN</Typography>
              )}
            </aside>
          </Link>
          <SideMenu />
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{background: '#fff'}}>
            <Header />
          </Layout.Header>
          <Layout.Content style={{margin: '16px'}}>
            <Card style={{margin: '16px 0'}}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/">
                    <Space>
                      <HomeOutlined /> Home
                    </Space>
                  </Link>
                </Breadcrumb.Item>
                {paths.map((path) => {
                  const child = route.children?.find((child) => child.path === path)
                  let href = `/${route.path}`
                  let name = route.displayName || route.name
                  let icon = undefined

                  if (child) {
                    href += `/${child.path}`
                    name = child.displayName || child.name
                  } else {
                    if (route.children && route.children.length > 0) href += `/${route.children[0].path}`
                    icon = route.icon
                  }

                  return (
                    <Breadcrumb.Item key={path}>
                      <Link to={href}>
                        <Space>
                          {icon}
                          {name}
                        </Space>
                      </Link>
                    </Breadcrumb.Item>
                  )
                })}
              </Breadcrumb>
              <Divider />
              {children}
            </Card>
          </Layout.Content>
        </Layout>
      </Layout>
    </UserProvider>
  )
}

export default DashboardLayout
