import React, {useCallback, useContext, useLayoutEffect, useMemo, useState, useEffect} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import queryString from 'query-string'
import InquiryTypesForm from './InquiryTypesForm'
import {deleteInquiryType, putInquiryType} from '../../api/inquiryTypes'
import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import getQuery from '../../lib/getQuery'

function InquiryTypesUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const {onDataFetch, data: inquiryTypes} = useContext(PageContext)

  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query = getQuery(search, {start: start || 0, perPage: 10})

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  useEffect(() => {
    const inquiryType = inquiryTypes?.data?.filter((item) => {
      return id === item.id
    })
    inquiryType && form.setFieldsValue(inquiryType[0])
  }, [inquiryTypes, id])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: '', search: queryString.stringify(query)}, {replace: true})
  }, [query])

  const onSuccess = useCallback(() => {
    message.success('정상적으로 처리되었습니다.')
    handleClose()
  }, [])
  const onError = useCallback((e: AxiosError<any, any>) => {
    if (e.response) message.error(e.response.data.message)
    else message.error('오류가 발생했습니다.')
  }, [])

  const {mutate: putMutate, isLoading: isPatchLoading} = useMutation(putInquiryType, {onSuccess, onError})
  const {mutate: deleteMutate, isLoading: isDeleteLoading} = useMutation(deleteInquiryType, {onSuccess, onError})

  const handleDelete = useCallback(() => {
    if (!id) return message.error('문의타입 데이터를 불러올 수 없습니다.')
    deleteMutate({id})
  }, [id])

  const handleFinish = useCallback(
    (data: IInquiryTypeData & {confirm?: string}) => {
      if (!id) return message.error('문의타입 데이터를 불러올 수 없습니다.')
      if (data.confirm) delete data.confirm
      putMutate({id, data})
    },
    [id]
  )

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
    }
    return () => {
      form.resetFields()
      if (onDataFetch) onDataFetch()
    }
  }, [id])

  return (
    <UpdateModal
      title={`문의타입 ${hash}`}
      open={isModalVisible}
      onOk={form.submit}
      onDelete={handleDelete}
      onCancel={handleClose}
      isPatchLoading={isPatchLoading}
      isDeleteLoading={isDeleteLoading}
      {...props}
    >
      <InquiryTypesForm type="update" form={form} onFinish={handleFinish} />
    </UpdateModal>
  )
}

export default InquiryTypesUpdateModal
