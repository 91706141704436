import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/feedbacks'

function postFeedback(data: IPostFeedbackData) {
  return request({url: PATH, method: 'POST', data})
}

function getFeedbacks(params: IGetFeedbacksParams): AxiosPromise<IFeedbacks> {
  return request({url: PATH, method: 'GET', params})
}

function getFeedback({id}: IGetFeedbackParams): AxiosPromise<IFeedback> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function patchFeedback({id, data}: IPatchFeedbackParams) {
  return request({url: `${PATH}/${id}`, method: 'PATCH', data})
}

export {postFeedback, getFeedback, getFeedbacks, patchFeedback}
