import {getSearchKeyword} from './index'
import {message} from 'antd'

async function handleFetchSearchKeyword(params: IGetSearchKeywordParams) {
  try {
    const {data} = await getSearchKeyword(params)

    if (!data.id || data.id !== params.id) throw new Error('해당 키워드 정보가 없습니다.')

    return data
  } catch (e: any) {
    message.error(e.message || '키워드 데이터를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return null
  }
}

export default handleFetchSearchKeyword
