import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/mateNotices'

function postMateNotice(data: IPostMateNoticeData) {
  return request({url: PATH, method: 'POST', data})
}

function getMateNotices(params: IGetMateNoticesParams): AxiosPromise<IMateNotices> {
  return request({url: PATH, method: 'GET', params})
}

function getMateNotice({id}: IGetMateNoticeParams): AxiosPromise<IMateNotice> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function patchMateNotice({id, data}: IPatchMateNoticeParams) {
  return request({url: `${PATH}/${id}`, method: 'PATCH', data})
}

function deleteMateNotice({id}: IDeleteMateNoticeParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {postMateNotice, getMateNotices, getMateNotice, patchMateNotice, deleteMateNotice}
