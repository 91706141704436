import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/inquiries'

function getInquiries(params: IGetInquiriesParams): AxiosPromise<IInquiries> {
  return request({url: PATH, method: 'GET', params})
}

function getInquire({id}: IGetInquireParams): AxiosPromise<IInquire> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

export {getInquire, getInquiries}
