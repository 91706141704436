import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'
import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import BlockForm from './BlockForm'
import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import handleFetchBlock from '../../api/blocks/handleFetchBlock'

function BlockUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const {onDataFetch} = useContext(PageContext)

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: ''}, {replace: true})
  }, [])

  const {
    isFetching,
    isSuccess,
    data: blockData
  } = useQuery('block', () => handleFetchBlock({id}), {
    enabled,
    onSuccess: (data: IBlock) => {
      form.setFieldsValue(data)
      setEnabled(false)
    },
    onError: (e: AxiosError<any, any>) => {
      if (e.response) message.error(e.response.data.message)
      setEnabled(false)
    }
  })

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
      setEnabled(true)
    }
    return () => {
      form.resetFields()
      if (onDataFetch) onDataFetch()
    }
  }, [id])

  return (
    <UpdateModal
      title={`신고 사용자 ${hash}`}
      visible={isModalVisible}
      onCancel={handleClose}
      isFetching={isFetching}
      onUpdate={false}
      {...props}
    >
      {isSuccess && <BlockForm blockData={blockData} />}
    </UpdateModal>
  )
}

export default BlockUpdateModal
