import React, {useCallback, useMemo} from 'react'
import {SearchProps} from 'antd/lib/input'
import {Input} from 'antd'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router'
import getQuery from '../../lib/getQuery'

function Search(props: SearchProps) {
  const {search} = useLocation()
  const navigate = useNavigate()
  const query: {userId?: string} = useMemo(() => getQuery(search), [])

  const handleSubmit = useCallback(
    (value: any) => {
      if (!value) delete query.userId
      else query.userId = value.toString()

      if (!query.userId) navigate(`/services/inquiries`)
      if (!!query.userId) navigate(`?userId=${Number(query.userId)}`)
    },
    [query]
  )

  return (
    <Input.Search defaultValue={query.userId} placeholder="사용자 ID" onSearch={handleSubmit} allowClear {...props} />
  )
}

export default Search
