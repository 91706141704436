import React, {useCallback, useMemo} from 'react'
import {SearchProps} from 'antd/lib/input'
import {Input} from 'antd'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router'
import getQuery from '../../lib/getQuery'

function Search(props: SearchProps) {
  const {search} = useLocation()
  const navigate = useNavigate()
  const query: {mateId?: string} = useMemo(() => getQuery(search), [])

  const handleSubmit = useCallback(
    (value: any) => {
      if (!value) delete query.mateId
      else query.mateId = value.toString()

      if (!query.mateId) navigate(`/contents/content`)
      if (!!query.mateId) navigate(`?mateId=${Number(query.mateId)}`)
    },
    [query]
  )

  return (
    <Input.Search defaultValue={query.mateId} placeholder="메이트 ID" onSearch={handleSubmit} allowClear {...props} />
  )
}

export default Search
