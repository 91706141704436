import {getMates} from './index'
import {message} from 'antd'

async function handleFetchMates(params: IGetMatesParams) {
  try {
    const {
      data: {data, total}
    } = await getMates(params)

    if (total < 1 && !!params.search) throw new Error('검색 결과가 없습니다')

    return {data, total}
  } catch (e: any) {
    message.error(e.message || '메이트 목록을 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return {data: [], total: 0}
  }
}

export default handleFetchMates
