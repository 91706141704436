import React, {useMemo} from 'react'
import {useLocation} from 'react-router'

import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'

import QuestionCreateModal from '../../components/Questions/QuestionCreateModal'
import QuestionUpdateModal from '../../components/Questions/QuestionUpdateModal'
import getQuery from '../../lib/getQuery'
import handleFetchQuestions from '../../api/questions/handleFetchQuestions'

const columns = [
  {key: 'date', dataIndex: 'date', title: '날짜'},
  {key: 'question', dataIndex: 'question', title: '질문'},
  {key: 'user', dataIndex: ['user', 'nickname'], title: '사용자', render: (data: string) => (!data ? '없음' : data)},
  {key: 'admin', dataIndex: ['admin', 'name'], title: '관리자', render: (data: string) => (!data ? '없음' : data)}
]

function Questions() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10, sort: 'createdAt', order: 'DESC'})

  return (
    <PageProvider onFetch={() => handleFetchQuestions(query)} uniqueKey="questions">
      <QuestionCreateModal />

      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />
      <QuestionUpdateModal />
    </PageProvider>
  )
}

export default Questions
