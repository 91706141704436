import React, {PropsWithChildren} from 'react'
import {Col, Row} from 'antd'
import ContentSearch from './ContentSearch'
import {SearchProps} from 'antd/lib/input'

function Actions({
  isSearch = true,
  searchProps,
  children
}: PropsWithChildren<{isSearch?: boolean; searchProps?: SearchProps}>) {
  return (
    <Row justify="space-between" gutter={8}>
      <Col flex="auto">{isSearch && <ContentSearch {...searchProps} />}</Col>

      <Col>{children}</Col>
    </Row>
  )
}

export default Actions
