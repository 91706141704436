import React, {useCallback, useMemo, useState} from 'react'
import {SearchOutlined} from '@ant-design/icons'
import {DatePicker, Button, Row} from 'antd'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router'
import getQuery from '../../lib/getQuery'
import moment from 'moment'

function BlockActions() {
  const {search} = useLocation()
  const navigate = useNavigate()
  const query = useMemo(() => getQuery(search), [])
  const {RangePicker} = DatePicker
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()

  const handleSubmit = useCallback(() => {
    if (!!startDate && !!endDate) {
      navigate(`?startDate=${startDate}&endDate=${endDate}`)
    }
    if (!startDate || !endDate) {
      navigate(`/user/blocks`)
    }
  }, [startDate, endDate, query])

  const handleChange = useCallback((e: any) => {
    if (e) {
      setStartDate(moment(e[0]).format('YYYY-MM-DD'))
      setEndDate(moment(e[1]).format('YYYY-MM-DD'))
    } else {
      setStartDate(undefined)
      setEndDate(undefined)
    }
  }, [])

  return (
    <>
      <div style={{marginBottom: '15px', fontWeight: '700'}}>기간 별 조회</div>
      <Row>
        <RangePicker onCalendarChange={handleChange} />
        <Button type="primary" icon={<SearchOutlined />} style={{marginLeft: '10px'}} onClick={handleSubmit}>
          Search
        </Button>
      </Row>
    </>
  )
}

export default BlockActions
