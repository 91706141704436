import React, {useMemo} from 'react'
import {useLocation} from 'react-router'

import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import handleFetchMateNotices from '../../api/mateNotices/handleFetchMateNotices'
import MateNoticeCreateModal from '../../components/MateNotices/MateNoticeCreateModal'
import MateNoticeUpdateModal from '../../components/MateNotices/MateNoticeUpdateModal'
import getQuery from '../../lib/getQuery'

const columns = [
  {key: 'title', dataIndex: 'title', title: '제목'},
  {key: 'description', dataIndex: 'description', title: '공지사항 내용'},
  {key: 'mateId', dataIndex: ['mate', 'id'], title: '메이트 ID'},
  {key: 'mateName', dataIndex: ['mate', 'name'], title: '메이트 이름'}
]

function MateNotices() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  return (
    <PageProvider onFetch={() => handleFetchMateNotices(query)} uniqueKey="mateNotices">
      <MateNoticeCreateModal />

      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />
      <MateNoticeUpdateModal />
    </PageProvider>
  )
}

export default MateNotices
