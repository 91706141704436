import React from 'react'
import {Form as AntdForm, FormProps, Input} from 'antd'
import Form from '../Form'
import Password from '../Password'

const {Item} = AntdForm

function AdministratorForm({type, ...rest}: FormProps & {type: 'create' | 'update'}) {
  return (
    <Form {...rest}>
      <Item
        name="name"
        label="이름"
        rules={[
          {required: type === 'create'},
          {type: 'string', min: 4, max: 20, message: '4 ~ 20자 이내로 입력해주세요.'}
        ]}
      >
        <Input placeholder="이름을 입력해주세요." autoComplete="off" />
      </Item>

      <Item
        name="nickname"
        label="닉네임"
        rules={[
          {required: type === 'create'},
          {type: 'string', min: 2, max: 20, message: '2 ~ 20자 이내로 입력해주세요.'}
        ]}
      >
        <Input placeholder="이름을 입력해주세요." autoComplete="off" />
      </Item>

      <Password
        rules={[
          {required: type === 'create'},
          {pattern: /^[\da-zA-Z!@#$%^&*()?+-_~=/]{6,40}$/, message: '올바르지 않는 형식입니다.'}
        ]}
      />

      <Password
        type="confirm"
        name="confirm"
        label="비밀번호 확인"
        rules={[
          {required: type === 'create'},
          {pattern: /^[\da-zA-Z!@#$%^&*()?+-_~=/]{6,40}$/, message: '올바르지 않는 형식입니다.'}
        ]}
      />
    </Form>
  )
}

export default AdministratorForm
