import React from 'react'
import {Form as AntdForm, FormProps, Input, InputNumber} from 'antd'
import Form from '../Form'

const {Item} = AntdForm

function MateNoticeForm({type, ...rest}: FormProps & {type: 'create' | 'update'}) {
  const {TextArea} = Input
  return (
    <Form {...rest}>
      <>
        <Item name="mateId" label="메이트 ID" rules={[{type: 'number'}, {required: type === 'create'}]}>
          <InputNumber placeholder="메이트 ID" autoComplete="off" min={0} />
        </Item>

        {type === 'update' && (
          <>
            <Item name="mateName" label="메이트 이름">
              <Input autoComplete="off" disabled />
            </Item>
          </>
        )}

        <Item name="title" label="제목" rules={[{type: 'string'}, {required: type === 'create'}]}>
          <Input placeholder="제목을 입력해주세요." autoComplete="off" />
        </Item>

        <Item name="description" label="공지사항 내용" rules={[{type: 'string'}, {required: type === 'create'}]}>
          <TextArea placeholder="공지사항 내용을 입력해주세요." autoComplete="off" />
        </Item>
      </>

      {type === 'update' && <></>}
    </Form>
  )
}

export default MateNoticeForm
