import React, {useMemo} from 'react'
import {useLocation} from 'react-router'

import AnswerActions from '../../components/Answers/AnswerActions'
import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import AnswerCreateModal from '../../components/Answers/AnswerCreateModal'
import AnswerUpdateModal from '../../components/Answers/AnswerUpdateModal'
import getQuery from '../../lib/getQuery'
import handleFetchAnswers from '../../api/answers/handleFetchAnswers'

const columns = [
  {key: 'user', dataIndex: ['user', 'nickname'], title: '사용자', render: (data: string) => (!data ? '없음' : data)},
  {key: 'mate', dataIndex: ['mate', 'name'], title: '메이트', render: (data: string) => (!data ? '없음' : data)},

  {key: 'question', dataIndex: ['question', 'question'], title: '질문'},
  {key: 'answer', dataIndex: 'answer', title: '답변'},
  {key: 'likeCount', dataIndex: 'likeCount', title: '좋아요 수'},
  {key: 'commentCount', dataIndex: 'commentCount', title: '댓글 수'}
]

function Answers() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10, sort: 'createdAt', order: 'DESC'})

  return (
    <PageProvider onFetch={() => handleFetchAnswers(query)} uniqueKey="answers">
      <AnswerActions>
        <AnswerCreateModal />
      </AnswerActions>
      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />
      <AnswerUpdateModal />
    </PageProvider>
  )
}

export default Answers
