import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/mateActions'

function postMateAction(data: IPostMateActionData) {
  return request({url: PATH, method: 'POST', data})
}

function getMateAction(params: IGetMateActionParams): AxiosPromise<IMateAction> {
  return request({url: PATH, method: 'GET', params})
}

export {postMateAction, getMateAction}
