import React, {useMemo} from 'react'
import {useLocation} from 'react-router'

import ContentActions from '../../components/Contents/ContentActions'
import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import ContentUpdateModal from '../../components/Contents/ContentUpdateModal'
import getQuery from '../../lib/getQuery'
import handleFetchContents from '../../api/contents/handleFetchContents'
import EditContent from '../../components/Contents/EditContent'

const columns = [
  {key: 'content', dataIndex: 'content', title: '내용'},
  {key: 'likeCount', dataIndex: 'likeCount', title: '좋아요 수'}
]

function Contents() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10, sort: 'createdAt'})

  return (
    <PageProvider onFetch={() => handleFetchContents(query)} uniqueKey="contents">
      <ContentActions />
      <EditContent />
      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />
      <ContentUpdateModal />
    </PageProvider>
  )
}

export default Contents
