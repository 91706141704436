import {createContext} from 'react'

const PageContext = createContext<IPageContextProps>({
  data: {
    data: [
      {
        createdAt: '',
        id: 0,
        nickname: '',
        updatedAt: ''
      }
    ],
    total: 0
  },

  isFetching: false
})

export default PageContext
