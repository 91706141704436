import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/inquiryTypes'

function postInquiryType(data: IInquiryTypeData) {
  return request({url: PATH, method: 'POST', data})
}

function getInquiryTypes(): AxiosPromise<IInquiryTypes> {
  return request({url: PATH, method: 'GET'})
}

function putInquiryType({id, data}: IPutInquiryTypeParams) {
  return request({url: `${PATH}/${id}`, method: 'PUT', data})
}

function deleteInquiryType({id}: IDeleteInquiryTypeParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {postInquiryType, getInquiryTypes, putInquiryType, deleteInquiryType}
