import React, {useContext} from 'react'
import {Button, Col, Dropdown, Menu, Row, Tooltip} from 'antd'
import {Link} from 'react-router-dom'
import {HomeOutlined, UserOutlined} from '@ant-design/icons'
import userContext from '../contexts/user/UserContext'

function Header() {
  const {user, onLogout} = useContext(userContext)

  return (
    <Row justify="end">
      <Col>
        <Tooltip title="홈페이지">
          <Link to="">
            <Button type="text">
              <HomeOutlined />
            </Button>
          </Link>
        </Tooltip>
      </Col>
      <Col>
        <Dropdown
          overlay={
            <Menu style={{textAlign: 'center'}} items={[{key: 'logout', label: '로그아웃', onClick: onLogout}]} />
          }
        >
          <Button type="text" htmlType="button">
            <UserOutlined />
            {user?.name}
          </Button>
        </Dropdown>
      </Col>
    </Row>
  )
}

export default Header
