import React, {useMemo} from 'react'
import {useLocation} from 'react-router'
import Actions from '../../components/Actions'
import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import handleFetchUsers from '../../api/users/handleFetchUsers'

import UserUpdateModal from '../../components/Users/UserUpdateModal'
import getQuery from '../../lib/getQuery'

const columns = [
  {key: 'nickname', dataIndex: 'nickname', title: '닉네임'},
  {key: 'email', dataIndex: 'email', title: '이메일'},
  {key: 'birth', dataIndex: 'birth', title: '생년월일'},
  {key: 'description', dataIndex: 'description', title: '메모'}
]

function Users() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10, sort: 'createdAt', order: 'DESC'})

  return (
    <PageProvider onFetch={() => handleFetchUsers(query)} uniqueKey="users">
      <Actions></Actions>

      <Table
        columns={columns}
        columnViews={{
          id: true,
          marketing: true,
          createdAt: true,
          profileImage: true
        }}
      />

      <UserUpdateModal />
    </PageProvider>
  )
}

export default Users
