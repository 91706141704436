import React from 'react'
import {Button, Modal, ModalProps, Popconfirm} from 'antd'
import ModalLoading from '../ModalLoading'

function UpdateModal({
  children,
  onDelete,
  onUpdate = true,
  isPatchLoading,
  isDeleteLoading,
  isFetching,
  ...rest
}: ModalProps & {
  onDelete?: () => any
  isPatchLoading?: boolean
  isDeleteLoading?: boolean
  isFetching?: boolean
  onUpdate?: boolean
}) {
  return (
    <>
      <Modal
        forceRender
        footer={[
          onDelete ? (
            <Popconfirm title="정말로 삭제하시겠습니까?" onConfirm={onDelete}>
              <Button htmlType="button" key="delete" danger loading={isDeleteLoading}>
                삭제
              </Button>
            </Popconfirm>
          ) : null,
          <Button htmlType="reset" key="cancel" onClick={rest.onCancel}>
            취소
          </Button>,

          onUpdate ? (
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={isPatchLoading}
              onClick={(e) => {
                if (rest.onOk) {
                  rest.onOk(e)
                }
              }}
            >
              수정
            </Button>
          ) : null
        ]}
        {...rest}
      >
        {!!children && !isFetching ? children : <ModalLoading />}
      </Modal>
    </>
  )
}

export default UpdateModal
