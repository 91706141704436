import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/reports'

function getReports(params: IGetReportsParams): AxiosPromise<IReports> {
  return request({url: PATH, method: 'GET', params})
}

function getReport({id}: IGetReportParams): AxiosPromise<IReport> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function deleteReport({id}: IDeleteReportParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {getReport, getReports, deleteReport}
