import React, {useMemo} from 'react'
import {Form, FormItemProps, Input} from 'antd'

function Password({rules = [], type = 'new', ...rest}: FormItemProps & {type?: 'new' | 'confirm'}) {
  const customRules = useMemo(() => {
    if (type === 'confirm')
      rules = [
        ...rules,
        ({getFieldValue}) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }

            return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'))
          },
          validateTrigger: ['onBlur']
        })
      ]

    return rules
  }, [rules])

  return (
    <>
      <Form.Item
        name="password"
        label="비밀번호"
        rules={customRules}
        validateTrigger={['onChange', 'onBlur']}
        {...rest}
      >
        <Input.Password placeholder="비밀번호를 입력해주세요." autoComplete="one-time-code" />
      </Form.Item>
    </>
  )
}

export default Password
