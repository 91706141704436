import React from 'react'

import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import handleFetchInquiryTypes from '../../api/inquiryTypes/handleFetchInquiryTypes'
import InquiryTypesCreateModal from '../../components/InquiryTypes/InquiryTypesCreateModal'
import InquiryTypesUpdateModal from '../../components/InquiryTypes/InquiryTypesUpdateModal'

const columns = [{key: 'name', dataIndex: 'name', title: '문의타입'}]

function InquiryTypes() {
  return (
    <PageProvider onFetch={() => handleFetchInquiryTypes()} uniqueKey="inquiryTypes">
      <InquiryTypesCreateModal />

      <Table
        columns={columns}
        columnViews={{
          id: true
        }}
      />
      <InquiryTypesUpdateModal />
    </PageProvider>
  )
}

export default InquiryTypes
