import React from 'react'
import {Card, Space} from 'antd'

function BlockForm({blockData}: {blockData: IBlock & any}) {
  return (
    <>
      <Space direction="vertical" size="middle" style={{display: 'flex'}}>
        <Card title="신고 사용자" size="small">
          <p>{blockData.user.nickname}</p>
        </Card>
        <Card title="신고 해당 사용자" size="small">
          <p>{blockData.blockUser.nickname}</p>
        </Card>
        <Card title="신고 사용자 ID" size="small">
          <p>{blockData.user.id}</p>
        </Card>
        <Card title="신고 해당 사용자 ID" size="small">
          <p>{blockData.blockUser.id}</p>
        </Card>
      </Space>
    </>
  )
}

export default BlockForm
