import request from '../../lib/request'
import {AxiosPromise} from 'axios'

const PATH = '/administrators'

function postAdministrator(data: IPostAdministratorData) {
  return request({url: PATH, method: 'POST', data})
}

function getAdministrators(params: IGetAdministratorsParams): AxiosPromise<IAdministrators> {
  return request({url: PATH, method: 'GET', params})
}

function getAdministrator({id}: IGetAdministratorParams) {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function patchAdministrator({id, data}: IPatchAdministratorParams) {
  return request({url: `${PATH}/${id}`, method: 'PATCH', data})
}

function deleteAdministrator({id}: IDeleteAdministratorParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {postAdministrator, getAdministrators, getAdministrator, patchAdministrator, deleteAdministrator}
