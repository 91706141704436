import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'
import {useQuery, useMutation} from 'react-query'
import {AxiosError} from 'axios'

import UserForm from './UserForm'
import queryString from 'query-string'
import {patchUser} from '../../api/users'
import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import handleFetchUser from '../../api/users/handleFetchUser'
import {uploadImage} from '../../lib/uploadImage'
import getQuery from '../../lib/getQuery'

function UserUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const {onDataFetch} = useContext(PageContext)
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: '', search: queryString.stringify(query)}, {replace: true})
  }, [query])

  const onSuccess = useCallback(() => {
    message.success('정상적으로 처리되었습니다.')
    handleClose()
  }, [])
  const onError = useCallback((e: AxiosError<any, any>) => {
    if (e.response) message.error(e.response.data.message)
    else message.error('오류가 발생했습니다.')
  }, [])

  const {isFetching, data: userData} = useQuery('user', () => handleFetchUser({id}), {
    enabled,
    onSuccess: (data: IUesr) => {
      form.setFieldsValue(data)
      setEnabled(false)
    },
    onError: (e: AxiosError<any, any>) => {
      if (e.response) message.error(e.response.data.message)
      setEnabled(false)
    }
  })
  const {mutate: patchMutate, isLoading: isPatchLoading} = useMutation(patchUser, {onSuccess, onError})

  const handleFinish = useCallback(
    async (data: IPatchUserData & {confirm?: string}) => {
      if (!id) return message.error('회원 데이터를 불러올 수 없습니다.')
      if (data.confirm) delete data.confirm

      if (!Object.keys(data).length) return message.error('변경 된 사항이 없습니다.')

      if (data.profileImage && typeof data.profileImage !== 'string') {
        const profileImage = await uploadImage(data.profileImage[0], 'images')
        data.profileImage = profileImage
      }

      patchMutate({id, data})
    },
    [id]
  )

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
      setEnabled(true)
    }
    return () => {
      form.resetFields()
      if (onDataFetch) onDataFetch()
    }
  }, [id])

  return (
    <>
      <UpdateModal
        title={`회원 ${hash}`}
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleClose}
        isPatchLoading={isPatchLoading}
        isFetching={isFetching}
        {...props}
      >
        <UserForm type="update" form={form} onFinish={handleFinish} userData={userData} />
      </UpdateModal>
    </>
  )
}

export default UserUpdateModal
