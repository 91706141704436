import React, {useMemo} from 'react'
import {useLocation} from 'react-router'

import Actions from '../../components/Actions'
import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import handleFetchAdministrators from '../../api/administrators/handleFetchAdministrators'
import AdministratorCreateModal from '../../components/Administrators/AdministratorCreateModal'
import AdministratorUpdateModal from '../../components/Administrators/AdministratorUpdateModal'
import getQuery from '../../lib/getQuery'

const columns = [
  {key: 'nickname', dataIndex: 'nickname', title: '닉네임'},
  {key: 'name', dataIndex: 'name', title: '이름'}
]

function Administrators() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10, sort: 'createdAt', order: 'DESC'})

  return (
    <PageProvider onFetch={() => handleFetchAdministrators(query)} uniqueKey="fetchAdministrators">
      <Actions>
        <AdministratorCreateModal />
      </Actions>
      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true,
          updatedAt: true
        }}
      />
      <AdministratorUpdateModal />
    </PageProvider>
  )
}

export default Administrators
