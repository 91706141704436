import {getInquiryTypes} from './index'
import {message} from 'antd'

async function handleFetchInquiryTypes() {
  try {
    const {
      data: {data}
    } = await getInquiryTypes()

    return {data}
  } catch (e: any) {
    message.error(e.message || '문의 타입 목록을 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return {data: [], total: 0}
  }
}

export default handleFetchInquiryTypes
