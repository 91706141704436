import React, {useMemo} from 'react'
import {useLocation} from 'react-router'

import FeedbackActions from '../../components/Feedbacks/FeedbackActions'
import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import handleFetchFeedbacks from '../../api/feedbacks/handleFetchFeedbacks'
import FeedbackCreateModal from '../../components/Feedbacks/FeedbackCreateModal'
import FeedbackUpdateModal from '../../components/Feedbacks/FeedbackUpdateModal'
import getQuery from '../../lib/getQuery'

const columns = [
  {key: 'description', dataIndex: 'description', title: '리뷰'},
  {key: 'rating', dataIndex: 'rating', title: '평점'},
  {
    key: 'user',
    dataIndex: ['user', 'nickname'],
    title: '작성자',
    render: (data: string) => (!data ? '없음' : data)
  },
  {key: 'mate', dataIndex: ['mate', 'name'], title: '메이트', render: (data: string) => (!data ? '없음' : data)}
]

function Feedbacks() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10, sort: 'createdAt', order: 'DESC'})

  return (
    <PageProvider onFetch={() => handleFetchFeedbacks(query)} uniqueKey="feedbacks">
      <FeedbackActions>
        <FeedbackCreateModal />
      </FeedbackActions>
      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />
      <FeedbackUpdateModal />
    </PageProvider>
  )
}

export default Feedbacks
