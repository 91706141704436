import React, {useMemo} from 'react'
import {useLocation} from 'react-router'

import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import handleFetchSearchKeywords from '../../api/searchKeywords/handleFetchSearchKeywords'
import SearchKeywordCreateModal from '../../components/SearchKeywords/SearchKeywordCreateModal'
import SearchKeywordUpdateModal from '../../components/SearchKeywords/SearchKeywordUpdateModal'
import getQuery from '../../lib/getQuery'

const columns = [
  {key: 'keyword', dataIndex: 'keyword', title: '키워드'},
  {key: 'count', dataIndex: 'count', title: '카운트'}
]

function SearchKeywords() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10, sort: 'createdAt', order: 'DESC'})

  return (
    <PageProvider onFetch={() => handleFetchSearchKeywords(query)} uniqueKey="keyWords">
      <SearchKeywordCreateModal />

      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true
        }}
      />
      <SearchKeywordUpdateModal />
    </PageProvider>
  )
}

export default SearchKeywords
