import React from 'react'
import {Form as AntdForm, FormProps, Input, Select} from 'antd'
import Form from '../Form'
import Upload from '../Upload'

const {Item} = AntdForm

function MateForm({type, ...rest}: FormProps & {type: 'create' | 'update'}) {
  return rest.form ? (
    <Form {...rest}>
      <Item name="hashtags" label="해시태그" rules={[{required: type === 'create'}]}>
        <Select mode="tags" placeholder="해시태그를 선택해주세요" />
      </Item>
      <Item name="sendbirdId" label="샌드버드 ID" rules={[{type: 'string'}, {required: type === 'create'}]}>
        <Input placeholder="샌드버드 ID를 입력해주세요." autoComplete="off" />
      </Item>
      <Item name="name" label="이름" rules={[{type: 'string'}, {required: type === 'create'}]}>
        <Input placeholder="이름을 입력해주세요." autoComplete="off" />
      </Item>

      <Item name="profileImage" label="프로필 이미지" valuePropName="values">
        <Upload max={1} title="profileImage" form={rest.form} />
      </Item>

      <Item name="backGroundImage" label="배경 이미지" valuePropName="values">
        <Upload max={1} title="backGroundImage" form={rest.form} />
      </Item>

      <Item name="label" label="메이트 라벨" rules={[{type: 'string'}, {required: type === 'create'}]}>
        <Input
          placeholder={type === 'update' ? '메이트 라벨' : '메이트 라벨을 입력해주세요.'}
          autoComplete="off"
          disabled={type === 'update' || false}
        />
      </Item>

      <Item name="action" label="메이트 액션" rules={[{type: 'string'}, {required: type === 'create'}]}>
        <Input
          placeholder={type === 'update' ? '메이트 액션' : '메이트 액션을 입력해주세요.'}
          autoComplete="off"
          disabled={type === 'update' || false}
        />
      </Item>

      <Item name="description" label="메모" rules={[{type: 'string'}]}>
        <Input placeholder="메모를 입력해주세요." autoComplete="off" />
      </Item>

      {type === 'update' && (
        <>
          <Item name="feedbacks" label="리뷰">
            <Input autoComplete="off" disabled />
          </Item>

          <Item name="dm" label="DM">
            <Input autoComplete="off" disabled />
          </Item>

          <Item name="follower" label="팔로워">
            <Input autoComplete="off" disabled />
          </Item>

          <Item name="like" label="좋아요">
            <Input autoComplete="off" disabled />
          </Item>
        </>
      )}
    </Form>
  ) : (
    <></>
  )
}
//피드백, 팔로워, 라이크, 디엠
export default MateForm
