import {getBlocks} from './index'
import {message} from 'antd'

async function handleFetchBlocks(params: IGetBlocksParams) {
  try {
    const {data} = await getBlocks(params)

    return data
  } catch (e: any) {
    message.error(e.message || '신고 자용자 목록을 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return
  }
}

export default handleFetchBlocks
