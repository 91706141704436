import {getMateNotices} from './index'
import {message} from 'antd'

async function handleFetchMateNotices(params: IGetSearchKeywordsParams) {
  try {
    const {
      data: {data, total}
    } = await getMateNotices(params)

    return {data, total}
  } catch (e: any) {
    message.error(e.message || '키워드 목록을 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return {data: [], total: 0}
  }
}

export default handleFetchMateNotices
