import React, {useMemo} from 'react'
import {useLocation} from 'react-router'

import Actions from '../../components/Actions'
import Table from '../../components/Table'
import PageProvider from '../../contexts/page/PageProvider'
import handleFetchMates from '../../api/mate/handleFetchMates'
import MateCreateModal from '../../components/Mates/MateCreateModal'
import MateUpdateModal from '../../components/Mates/MateUpdateModal'
import getQuery from '../../lib/getQuery'

const columns = [
  {key: 'name', dataIndex: 'name', title: '이름'},
  {key: 'sendbirdId', dataIndex: 'sendbirdId', title: '샌드버드 아이디'},
  {key: 'description', dataIndex: 'description', title: '메모'},
  {key: 'dm', dataIndex: 'dm', title: 'DM'}
]

function Mates() {
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10, sort: 'createdAt', order: 'DESC'})

  return (
    <PageProvider onFetch={() => handleFetchMates(query)} uniqueKey="mates">
      <Actions>
        <MateCreateModal />
      </Actions>
      <Table
        columns={columns}
        columnViews={{
          id: true,
          createdAt: true,
          profileImage: true,
          backGroundImage: true
        }}
      />
      <MateUpdateModal />
    </PageProvider>
  )
}

export default Mates
