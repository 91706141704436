import React, {useCallback, useMemo} from 'react'
import {SearchProps} from 'antd/lib/input'
import {Input, Radio} from 'antd'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router'
import getQuery from '../../lib/getQuery'
import type {RadioChangeEvent} from 'antd'

function Search(props: SearchProps) {
  const {search} = useLocation()
  const navigate = useNavigate()
  const query: {userId?: string; type?: 'comment' | 'answer' | 'answerComment' | 'guestBook' | 'all'} = useMemo(
    () => getQuery(search),
    []
  )

  const handleSubmit = useCallback(
    (value: any) => {
      if (!value) delete query.userId
      else query.userId = value.toString()

      if (!query.userId) navigate(`/services/inquiries`)
      if (!!query.userId) navigate(`?userId=${Number(query.userId)}`)
    },
    [query]
  )

  const onChangeRadio = (e: RadioChangeEvent) => {
    if (e.target.value === 'all') {
      navigate(`/contents/reports`)
      return
    }
    if (!e.target.value) delete query.type
    else query.userId = e.target.value.toString()

    if (e.target.value === 'all') navigate(`/contents/reports`)
    if (!!query.userId) navigate(`?type=${e.target.value}`)
  }

  return (
    <>
      <Input.Search defaultValue={query.userId} placeholder="사용자 ID" onSearch={handleSubmit} allowClear {...props} />
      <Radio.Group onChange={onChangeRadio} defaultValue="all" style={{marginTop: '25px'}}>
        <Radio.Button value="all">전체</Radio.Button>
        <Radio.Button value="comment">게시글 댓글</Radio.Button>
        <Radio.Button value="answer">오늘의 질문에 대한 답변</Radio.Button>
        <Radio.Button value="answerComment">답변에 대한 댓글</Radio.Button>
        <Radio.Button value="guestBook">방명록</Radio.Button>
      </Radio.Group>
    </>
  )
}

export default Search
