import {getReport} from './index'
import {message} from 'antd'

async function handleFetchReport(params: IGetReportParams) {
  try {
    const {data} = await getReport(params)

    if (!data.id || data.id !== params.id) throw new Error('해당 신고 댓글 정보가 없습니다.')

    return data
  } catch (e: any) {
    message.error(e.message || '신고 댓글 데이터를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return null
  }
}

export default handleFetchReport
