import {getAnswers} from './index'
import {message} from 'antd'

async function handleFetchAnswers(params: IGetAnswersParams) {
  try {
    const {
      data: {data, total}
    } = await getAnswers(params)

    if (total < 1 && !!params.questionId) throw new Error('검색 결과가 없습니다')

    return {data, total}
  } catch (e: any) {
    message.error(e.message || '질문 답변 목록을 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return {data: [], total: 0}
  }
}

export default handleFetchAnswers
