import React from 'react'
import {Form as AntdForm, FormProps, Input, Radio, Card} from 'antd'
import Form from '../Form'

const {Item} = AntdForm

function QuestionForm({type, ...rest}: FormProps & {type: 'create' | 'update'} & {question?: IQuestion}) {
  return (
    <Form {...rest}>
      <>
        <Item name="question" label="질문" rules={[{type: 'string'}, {required: type === 'create'}]}>
          <Input placeholder="질문을 입력해주세요." autoComplete="off" />
        </Item>

        <Item name="picked" label="선택여부" rules={[{required: type === 'create'}]}>
          <Radio.Group>
            <Radio.Button value="true">선택됨</Radio.Button>
            <Radio.Button value="false">선택안됨</Radio.Button>
          </Radio.Group>
        </Item>
      </>

      {type === 'update' && (
        <>
          <Item label="사용자 ID">
            <Input
              placeholder={!!rest.question?.user?.id ? String(rest.question?.user?.id) : '없음'}
              autoComplete="off"
            />
          </Item>
          <Item label="사용자 이름">
            <Input
              placeholder={!!rest.question?.user?.nickname ? String(rest.question?.user?.nickname) : '없음'}
              autoComplete="off"
            />
          </Item>
          <Item label="관리자 ID">
            <Input
              placeholder={!!rest.question?.admin?.id ? String(rest.question?.admin?.id) : '없음'}
              autoComplete="off"
            />
          </Item>
          <Item label="관리자 이름">
            <Input
              placeholder={!!rest.question?.admin?.name ? String(rest.question?.admin?.name) : '없음'}
              autoComplete="off"
            />
          </Item>

          {rest.question?.expectMates.map((item) => {
            return (
              <Card title="예상 메이트" size="small" key={item.id}>
                <p>ID: {item.id}</p>
                <p>이름: {item.name}</p>
              </Card>
            )
          })}

          {rest.question?.answers.map((item) => {
            return (
              <Card title="예상 답변" size="small" key={item.id}>
                <p>ID: {item.id}</p>
                <p>답변: {item.answer}</p>
                <p>메이트 ID: {item.mate.id}</p>
                <p>메이트 이름: {item.mate.name}</p>
              </Card>
            )
          })}
        </>
      )}
    </Form>
  )
}

export default QuestionForm
