import request from '../../lib/request'
import {AxiosPromise} from 'axios'

const PATH = '/contents'

function getContentComments({id}: IGetContentCommentsParams): AxiosPromise<IContentComments> {
  return request({url: `${PATH}/${id}/comments`, method: 'GET'})
}

function getContents(params: IGetContentsParams): AxiosPromise<IContents> {
  return request({url: PATH, method: 'GET', params})
}

function getContent({id}: IGetContentParams): AxiosPromise<IContent> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function putContent(data: IPutContentParams & any) {
  return request({url: PATH, method: 'PUT', data})
}

function deleteContentComments({id}: IDeleteContentCommentsParams) {
  return request({url: `${PATH}/comments/${id}`, method: 'DELETE'})
}

export {getContent, getContents, getContentComments, putContent, deleteContentComments}
