import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/users'

function getUsers(params: IGetUsersParams): AxiosPromise<IUsers> {
  return request({url: PATH, method: 'GET', params})
}

function getUser({id}: IGetUserParams): AxiosPromise<IUser> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function patchUser({id, data}: IPatchUserParams) {
  return request({url: `${PATH}/${id}`, method: 'PATCH', data})
}

export {getUser, getUsers, patchUser}
