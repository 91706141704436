import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'
import {useMutation, useQuery} from 'react-query'
import {AxiosError} from 'axios'
import queryString from 'query-string'

import MateForm from './MateForm'
import {patchMate} from '../../api/mate'
import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import handleFetchMate from '../../api/mate/handleFetchMate'
import getQuery from '../../lib/getQuery'
import {uploadImage} from '../../lib/uploadImage'

function MateUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const {onDataFetch} = useContext(PageContext)
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: '', search: queryString.stringify(query)}, {replace: true})
  }, [query])

  const onSuccess = useCallback(() => {
    message.success('정상적으로 처리되었습니다.')
    handleClose()
  }, [])
  const onError = useCallback((e: AxiosError<any, any>) => {
    if (e.response) message.error(e.response.data.message)
    else message.error('오류가 발생했습니다.')
  }, [])

  const {isFetching} = useQuery('mate', () => handleFetchMate({id}), {
    enabled,
    onSuccess: (data: IMate) => {
      const formedData = {
        ...data,
        hashtags: data.hashtags.map((item: any) => {
          return item.name
        })
      }
      form.setFieldsValue(formedData)
      setEnabled(false)
    },
    onError: (e: AxiosError<any, any>) => {
      if (e.response) message.error(e.response.data.message)
      setEnabled(false)
    }
  })
  const {mutate: patchMutate, isLoading: isPatchLoading} = useMutation(patchMate, {onSuccess, onError})

  // useEffect(() => {
  //   const fetchMateAction = async () => {
  //     const mateAction =
  //       !!id &&
  //       (await getMateAction({
  //         mateId: id,
  //         start: 0,
  //         perPage: 10
  //       }))
  //     form.setFieldsValue({
  //       label: mateAction?.title,
  //       action: mateAction?.description
  //     })
  //   }
  //   fetchMateAction()
  // }, [id])

  const handleFinish = useCallback(
    async (data: IPatchMateData & {confirm?: string}) => {
      if (!id) return message.error('메이트 데이터를 불러올 수 없습니다.')
      if (data.confirm) delete data.confirm

      try {
        if (data.profileImage && typeof data.profileImage !== 'string') {
          data.profileImage = await uploadImage(data.profileImage[0], 'images')
        }
        if (data.backGroundImage && typeof data.backGroundImage !== 'string') {
          data.backGroundImage = await uploadImage(data.backGroundImage[0], 'images')
        }
      } catch (error) {
        message.error('프로필 이미지, 배경 이미지를 첨부해주세요.')
        return
      }

      const formedData: IPatchMateData = {
        ...data,
        hashtags: data?.hashtags?.map((item: string) => {
          return {
            name: item
          }
        })
      }

      patchMutate({id, data: !!data.hashtags ? formedData : data})
    },
    [id]
  )

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
      setEnabled(true)
    }
    return () => {
      form.resetFields()
      if (onDataFetch) onDataFetch()
    }
  }, [id])

  return (
    <UpdateModal
      title={`메이트 ${hash}`}
      visible={isModalVisible}
      onOk={form.submit}
      onCancel={handleClose}
      isPatchLoading={isPatchLoading}
      isFetching={isFetching}
      {...props}
    >
      <MateForm type="update" form={form} onFinish={handleFinish} />
    </UpdateModal>
  )
}

export default MateUpdateModal
