import {PropsWithChildren, useCallback, useEffect, useLayoutEffect, useReducer, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useQuery} from 'react-query'
import {AxiosError, AxiosResponse} from 'axios'
import {message} from 'antd'
import PageContext from './PageContext'

const defaultValue = {
  data: [],
  isFetching: false,
  total: 0
}

function reducer(state: any, action: {type: string; payload?: any}) {
  switch (action.type) {
    case 'GET_LIST':
      return {...state, ...action.payload}
    case 'CHANGE_FATCHING_STATUS':
      return {...state, isFetching: action.payload}
    default:
      return defaultValue
  }
}

function PageProvider({children, onFetch, uniqueKey}: PropsWithChildren<IPageProviderProps>) {
  const {search} = useLocation()
  const [state, dispatch] = useReducer(reducer, defaultValue)
  const [enabled, setEnabled] = useState(false)

  const onSuccess = useCallback(
    (data: AxiosResponse<any, any>) => {
      dispatch({type: 'GET_LIST', payload: {data}})
      setEnabled(false)
    },
    [dispatch]
  )

  const onError = useCallback((e: AxiosError<any, any>) => {
    if (e.response) message.error(e.response.data.message)
    setEnabled(false)
  }, [])

  const {isFetching} = useQuery(uniqueKey || '', () => onFetch && onFetch(), {
    enabled,
    onSuccess,
    onError
  })

  const handleDataFetch = useCallback(
    (search: any) => {
      if (!onFetch) return dispatch({type: 'INIT'})
      setEnabled(true)
    },
    [onFetch]
  )

  useEffect(() => dispatch({type: 'CHANGE_FATCHING_STATUS', payload: isFetching}), [isFetching])

  useLayoutEffect(() => {
    handleDataFetch(search)
  }, [search])

  return <PageContext.Provider value={{...state, onDataFetch: handleDataFetch}}>{children}</PageContext.Provider>
}

export default PageProvider
