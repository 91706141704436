import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'
import {useQuery, useMutation} from 'react-query'
import {AxiosError} from 'axios'
import queryString from 'query-string'

import ReportForm from './ReportForm'
import {deleteReport} from '../../api/reports'
import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import handleFetchReport from '../../api/reports/handleFetchReport'
import getQuery from '../../lib/getQuery'

function AdministratorUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const {onDataFetch} = useContext(PageContext)
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: '', search: queryString.stringify(query)}, {replace: true})
  }, [query])

  const onSuccess = useCallback(() => {
    message.success('정상적으로 처리되었습니다.')
    handleClose()
  }, [])
  const onError = useCallback((e: AxiosError<any, any>) => {
    if (e.response) message.error(e.response.data.message)
    else message.error('오류가 발생했습니다.')
  }, [])

  const {isFetching, data: report} = useQuery('report', () => handleFetchReport({id}), {
    enabled,
    onSuccess: (data: IReport) => {
      form.setFieldsValue(data)
      setEnabled(false)
    },
    onError: (e: AxiosError<any, any>) => {
      if (e.response) message.error(e.response.data.message)
      setEnabled(false)
    }
  })

  const {mutate: deleteMutate, isLoading: isDeleteLoading} = useMutation(deleteReport, {onSuccess, onError})

  const handleDelete = useCallback(() => {
    if (!id) return message.error('신고 댓글을 불러올 수 없습니다.')
    deleteMutate({id})
  }, [id])

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
      setEnabled(true)
    }
    return () => {
      form.resetFields()
      if (onDataFetch) onDataFetch()
    }
  }, [id])

  return (
    <UpdateModal
      title={`신고 댓글 ${hash}`}
      open={isModalVisible}
      onOk={form.submit}
      onDelete={handleDelete}
      onCancel={handleClose}
      isDeleteLoading={isDeleteLoading}
      isFetching={isFetching}
      onUpdate={false}
      {...props}
    >
      {report && <ReportForm report={report} />}
    </UpdateModal>
  )
}

export default AdministratorUpdateModal
