import {getBlock} from './index'
import {message} from 'antd'

async function handleFetchBlock(params: IGetBlockParams) {
  try {
    const {data} = await getBlock(params)

    return data
  } catch (e: any) {
    message.error(e.message || '신고 사용자를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return
  }
}

export default handleFetchBlock
