import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/mate'

function postMate(data: IPostMateData) {
  return request({url: PATH, method: 'POST', data})
}

function getMates(params: IGetMatesParams): AxiosPromise<IMates> {
  return request({url: PATH, method: 'GET', params})
}

function getMate({id}: IGetMateParams): AxiosPromise<IMate> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function patchMate({id, data}: IPatchMateParams) {
  return request({url: `${PATH}/${id}`, method: 'PATCH', data})
}

export {postMate, getMate, getMates, patchMate}
