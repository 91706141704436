import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'
import {useQuery, useMutation} from 'react-query'
import {AxiosError} from 'axios'
import queryString from 'query-string'

import ContentForm from './ContentForm'
import {deleteContentComments} from '../../api/contents'
import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import handleFetchContent from '../../api/contents/handleFetchContent'
import handleFetchContentComments from '../../api/contents/handleFetchContentComments'
import getQuery from '../../lib/getQuery'

function ContentUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const {onDataFetch} = useContext(PageContext)
  const {search} = useLocation()
  const {start} = useMemo(() => getQuery(search), [search])
  const query: any = getQuery(search, {start: start || 0, perPage: 10})

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: '', search: queryString.stringify(query)}, {replace: true})
  }, [query])

  const onSuccess = useCallback(() => {
    refetch()
    message.success('정상적으로 처리되었습니다.')
  }, [])
  const onError = useCallback((e: AxiosError<any, any>) => {
    if (e.response) message.error(e.response.data.message)
    else message.error('오류가 발생했습니다.')
  }, [])

  const {isFetching, data: content} = useQuery('content', () => handleFetchContent({id}), {
    enabled,
    onSuccess: (data: IContent) => {
      form.setFieldsValue(data)
      setEnabled(false)
    },
    onError: (e: AxiosError<any, any>) => {
      if (e.response) message.error(e.response.data.message)
      setEnabled(false)
    }
  })

  const {data: contentComments, refetch} = useQuery('contentComments', () => handleFetchContentComments({id}), {
    enabled,
    onSuccess: (data: IContentComments) => {
      setEnabled(false)
    },
    onError: (e: AxiosError<any, any>) => {
      if (e.response) message.error(e.response.data.message)
      setEnabled(false)
    }
  })

  const {mutate: deleteMutateContentComments} = useMutation(deleteContentComments, {
    onSuccess,
    onError
  })

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
      setEnabled(true)
    }
    return () => {
      form.resetFields()
      if (onDataFetch) onDataFetch()
    }
  }, [id])

  return (
    <UpdateModal
      title={`게시글 ${hash}`}
      visible={isModalVisible}
      onCancel={handleClose}
      isFetching={isFetching}
      onUpdate={false}
      {...props}
    >
      <ContentForm
        content={content}
        contentComments={contentComments}
        deleteMutateContentComments={deleteMutateContentComments}
      />
    </UpdateModal>
  )
}

export default ContentUpdateModal
