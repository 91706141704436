import {AxiosPromise} from 'axios'
import request from '../../lib/request'

const PATH = '/answers'

function postAnswer(data: IPostAnswerData) {
  return request({url: PATH, method: 'POST', data})
}

function getAnswers(params: IGetAnswersParams): AxiosPromise<IAnswers> {
  return request({url: PATH, method: 'GET', params})
}

function getAnswer({id}: IGetAnswerParams): AxiosPromise<IAnswer> {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function putAnswer({id, data}: IPutAnswerParams) {
  return request({url: `${PATH}/${id}`, method: 'PUT', data})
}

export {postAnswer, getAnswer, getAnswers, putAnswer}
